import React from 'react';
import Fade from 'react-reveal/Fade';
import styled from 'styled-components';
import img from '../img/sign_b.png';

const About = () => {
  return(
    <Section id="about">
      <AboutDiv className="row">
        <div className="col-md-3 offset-md-2">
          <Fade left>
            <H3>ABOUT</H3>
            <i className="fas fa-angle-double-right fa-lg space-up-down"></i>
            <Par>
              I am a hobbyist wildlife photographer. I travel around Nepal in search of wildlife. Being a software engineer, weekends are when I photograph.
              So, on Saturdays and Sundays, I travel around the outskirts of Kathmandu Valley looking for birds and animals and during holidays I travel to different 
              national parks. Chitwan National Park has always been my favourite destination but the search for Royal Bengal Tiger is still on.
            </Par>
          </Fade>
        </div>
      </AboutDiv>
    </Section>
  )
}

export default About;

const Section = styled.section`
  color: white;
  background: #1c1c1c;
  height: 650px;
  display: table;
`

const AboutDiv = styled.div`
  display: table-cell;
  vertical-align: middle;
  background-image: url(${img});
  background-position: 65% 27%;
  background-repeat: no-repeat;
  background-attachment: fixed;
`

const Par = styled.p`
  font-size: 14px;
`

const H3 = styled.h3`
  margin-bottom: 0px;
  letter-spacing: 6px;
  font-weight: 550;
`
