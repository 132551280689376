import React, {useState, useEffect} from 'react';
import Initial from './Initial';
import PhotoGallery from './PhotoGallery';
import About from './About';
import Footer from './Footer';
import Loading from './Loading';
import img from '../img/pano.jpg';

const HomePage = () => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const image = new Image();
    image.src = img;
    image.onload = () => {
      setIsLoading(false);
    }
  })

  return(
    <React.Fragment>
      <Loading  display= {isLoading ? 'table' : 'none' }/>
      <div style={{ display: isLoading ? 'none' : 'block' }}>
        <Initial img={img}/>
        <About />
        <PhotoGallery />
        <Footer />
      </div>
    </React.Fragment>
  )
}

export default HomePage;