import React from 'react';
import styled from 'styled-components';

const Footer = () => {
  return(
    <Section id="footer">
      <div className="container">
        <Icons>
          <Link href="https://500px.com/shashwatsapkota09" target="_blank"><i className="fab fa-500px fa-2x"></i></Link>
          &nbsp;
          &nbsp;
          <Link href="https://www.instagram.com/sapkotashashwat/" target="_blank"><i className="fab fa-instagram fa-2x"></i></Link>
        </Icons>
        <p>2020 © <b>Shashwat Sapkota</b>. All rights reserved.</p>
      </div>
    </Section>
  )
}

export default Footer;

const Section = styled.section`
  padding: 3em 0 3em 0;
  background: #1c1c1c;
  text-align: center;
  color: white;
  scroll-behavior: smooth;
`

const Icons = styled.div`
  padding-bottom: 15px;
  text-decoration: none;
`

const Link = styled.a`
  color: white;
`
