import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import HomePage from './component/HomePage';
import './style/index.css'
import img from './img/pano.jpg';

function App() {
  return (
    <div className="App">
      <HomePage img={img}/>
    </div>
  );
}

export default App;
