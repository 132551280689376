import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import { Link, animateScroll as scroll } from "react-scroll";

const Header = () => {
  const [scroll, setScroll] = useState(false);

  useEffect(() => {
    document.addEventListener("scroll", () => {
      const scrollCheck = window.scrollY > 10
      if (scrollCheck !== scroll) {
        setScroll(scrollCheck);
      }
    })
  })

  return(
    <Navbar fixed="top" id="navbar" className={ scroll ? 'navbar-dark bg-light-gray animation' : 'navbar-dark animation' } expand="lg">
      <Navbar.Brand href="#">
        <Signature>Shashwat Sapkota</Signature>
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav" >
        <Nav className="ml-auto">
          <Nav.Link href="#home" className="navigation-link" onClick={scrollToTop}>Home</Nav.Link>
          <Link
            activeClass="active"
            to="about"
            spy={true}
            smooth={true}
            offset={-70}
            duration= {500}
            className="navigation-link nav-link"
          >About</Link>
          <Link
            activeClass="active"
            to="gallery"
            spy={true}
            smooth={true}
            offset={-70}
            duration= {500}
            className="navigation-link nav-link"
          >Gallery</Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}

const scrollToTop = () => {
  scroll.scrollToTop();
}

export default Header;

const Signature = styled.div`
  font-family: 'Lovers Quarrel', cursive;
  display: inline-block;
  font-size: 40px;
  color: transparent;
`
