import React from 'react';
import styled from 'styled-components';
import signature from '../img/sign.gif'

const Loading = ({display}) => {
  return(
    <LoadingScreen style={{display: display}}>
      <Logo>
        <img src={signature} alt=""></img>
      </Logo> 
    </LoadingScreen>
  )
}

export default Loading;

const LoadingScreen = styled.div`
  display: table;
  background: #1b1b1b;
  height: 100vh;
  width: 100%;
`

const Logo = styled.div`
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  color: #fff;
`