import React from 'react';
import styled from 'styled-components';
import Header from './Header';
import Info from './Info';

const Initial = ({img}) => {
  return(
    <Background className="img-fluid" style={{backgroundImage: `url(${img})`}}>
      <Header/>
      <Info />
    </Background>
  );
}

export default Initial;

const Background = styled.div`
  height: 978px;
  background: gray;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
`
