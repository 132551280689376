import React from 'react';
import styled from 'styled-components';
import img from '../img/sign.png';

const Info = () => {
  return(
    <DisplayTable className="container">
      <TableCell>
        <Intro>
          <img src={img} className="img-fluid" alt=""/>
          <LargeFont>Save Nature</LargeFont>
          <p>Nature and Humans, we are meant to co-exist</p>
        </Intro>
      </TableCell>
    </DisplayTable>
  );
}

export default Info;

const DisplayTable = styled.div`
  width: 100%;
  height: 100%;
  display: table;
`

const TableCell = styled.div`
  display: table-cell;
  vertical-align: middle;
`

const Intro = styled.div`
  width: 100%;
  color: #fff;
  text-align: center;
`

const LargeFont = styled.h3`
  font-size: 2em;
  letter-spacing: 3px;
  font-weight: 400;
`